
    .creationCompetition{
        /*height: calc(100vh - 430px);*/
        height: 100%;
        box-sizing: border-box;
        /*padding: 30px;*/
        display: flex;
        flex-direction: column;
        /*flex-direction: column;*/
        .creationCompetitionProgress{
            /*padding: 60px 228px 100px 228px;*/
            /*display: flex;*/
            /*align-items: center;*/
            /*margin: 64px auto;*/
            display: flex;
            .competitionProgress{
                margin: 64px auto;
                display: flex;
                align-items: center;
                .step-num{
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    span:nth-child(1){
                        display: inline-block;
                        width: 48px;
                        height: 48px;
                        font-size: 24px;
                        color: #FFFFFF;
                        background: #DCDCDC;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 48px;
                    }
                    .active{
                        background: #1122D8!important;
                    }
                    span:nth-child(2){
                        display: inline-block;
                        width: 100px;
                        text-align: center;
                        top: 68px;
                        /*left: 30%;*/
                        position: absolute;
                        font-size: 16px;
                        color: #333333;

                    }
                }
                .step-wire{
                    height: 14px;
                    margin: 0 10px;
                }
            }

        }
    }
